<template>
  <el-dialog title="打印小票" :visible="visible" width="60" v-on="$listeners">
    <div :span="8" class="m-b-20" id="printerWrap">
      <div class="topInfo">
        <h3 class="text-center line-h-40">联生系农贸市场</h3>
        <p class="line-h-25">日期：{{ item.createtime }}</p>
        <p class="line-h-25">订单号：{{ item.id }}</p>
      </div>
      <el-row class="ticketTop ">
        <el-col :span="8" class="line-h-35">名称</el-col>
        <el-col :span="8" class="text-center line-h-35">数量*单价</el-col>
        <el-col :span="8" class="text-center line-h-35">金额</el-col>
      </el-row>
      <el-row v-for="(itemc, indexc) in item.orderGoods" :key="indexc">
        <el-col :span="24" class="line-h-30">{{ itemc.name }}</el-col>
        <el-col :span="8" :offset="8" class="text-center line-h-25 ">
          {{ itemc.count }}*{{ itemc.price }}</el-col
        >
        <el-col :span="8" class="text-center line-h-25">
          {{ itemc.subtotal }}
        </el-col>
      </el-row>
      <el-row class="ticketTop p-t-10 p-b-10">
        <el-col :span="12">应付款：{{ item.totalPrice }}元</el-col>
        <el-col :span="12">件数：{{ getCount(item) }}件</el-col>
        <el-col :span="24">实付款：{{ item.price }}元</el-col>
        <el-col :span="12">抵扣金额：{{ item.deductionPrice }}元</el-col>
        <el-col :span="12"> 抵扣积分：{{ item.deductionIntegral }}分</el-col>
      </el-row>
      <el-row class="ticketTop p-t-10">
        <el-col :span="24" class="line-h-30">
          客服电话：0771-3827160
        </el-col>
        <el-col :span="24">
          请当面清点所购商品和找零，并保管好收银小票以做开发票、退换货凭证，谢谢惠顾。
        </el-col>
      </el-row>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('update:visible', false)">取消</el-button>
      <el-button type="primary" v-print="'#printerWrap'">打印</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: { visible: Boolean, item: Object },
  methods: {
    getCount(arr) {
      let total = 0;
      try {
        arr.orderGoods.forEach(item => {
          total = total + parseInt(item.count);
        });
        return total;
      } catch (error) {
        return 0;
      }
    }
  }
};
</script>

<style lang="scss">
.printerWrap {
  margin: 0 auto;
}

.ticketTop {
  border-top: 1px dashed #333;
}

.ticeketItem {
  line-height: 40px;
  text-align: center;
}

.newPrinterWrap {
  width: 193px;
}

.printerWrap {
  margin: 0 auto;
}
</style>
