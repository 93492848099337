<template>
  <div class="app_con">
    <search-bar
      show-write
      :props="searchProps"
      @searchChange="searchChange"
      @write="onWrite"
    />
    <div class="tableWrap">
      <list-table
        v-loading="loading"
        :data="dataList"
        @jump="onJump"
        @deliver="onDeliver"
        @refund="onRefund"
        @refund-manual="onRefundManual"
        @print="onPrint"
      />
    </div>
    <el-pagination
      background
      :current-page.sync="pageInfo.pageNum"
      :page-size="pageInfo.pageSize"
      :total="pageInfo.total"
      :layout="'sizes, prev, pager, next, jumper'"
      @size-change="onSizeChange"
      @current-change="onPageChange"
    />
    <el-dialog
      title="发货"
      :visible.sync="dialogShow"
      width="1000px"
      :close-on-click-modal="false"
      @close="dialogShow = false"
    >
      <el-form :model="form" ref="ruleForm" label-width="100px">
        <el-form-item label="订单编号" prop="id">
          <el-input v-model="form.id" size="small" :disabled="true"></el-input>
        </el-form-item>

        <el-form-item label="配送方式" prop="id">
          <el-select
            v-model="form.distributionMode"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in wayList"
              :key="item.name"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="物流单号"
          prop="trackingNumber"
          v-if="form.distributionMode == 0"
        >
          <el-input
            v-model="form.trackingNumber"
            placeholder="请输入账号"
            size="small"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm">发货</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="退款操作"
      :visible.sync="refundDialogShow"
      width="500px"
      :close-on-click-modal="false"
      @close="dialogShow = false"
    >
      <el-form :model="refundForm" ref="ruleForm" label-width="100px">
        <el-form-item label="订单编号" prop="id">
          <el-input
            v-model="refundForm.id"
            size="small"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="退款操作" prop="id">
          <el-select
            v-model="refundForm.refundStatus"
            filterable
            placeholder="请选择"
          >
            <el-option label="同意退款" :value="3"></el-option>
            <el-option label="拒绝退款" :value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button @click="refundDialogShow = false">取消</el-button>
          <el-button type="primary" @click="submitrefundForm">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <list-ticket :visible.sync="printerDialogShow" :data="printerData" />
    <list-write
      :visible.sync="writeOffShow"
      :value.sync="writeOffId"
      :data="writeOffData"
      @enter="onWriteEnter"
      @write="onWriteConfirm"
      @close="onWriteClose"
    />
  </div>
</template>

<script>
import api from '@/api';
import store from '@/store';
import listTable from './listTable';
import listTicket from './listTicket';
import listWrite from './listWrite';

export default {
  components: { listTable, listTicket, listWrite },
  data() {
    return {
      writeOffData: undefined,
      writeOffId: '',
      writeOffShow: false,
      refundDialogShow: false,
      loading: false,
      printerDialogShow: false,
      printerData: {},
      refundForm: { id: '', refundStatus: 3 },
      form: { id: '', distributionMode: 0, trackingNumber: '' },
      dialogShow: false,
      remote: api.common,
      typeList: [],
      storeList: [],
      apiName: '/order',
      searchVal: '',
      bntLoading: false,
      wayList: [
        { name: '物流配送', value: 0 },
        { name: '商家配送', value: 1 },
        { name: '无需配送', value: 2 },
        { name: 'UU跑腿', value: 3 }
      ],
      orderStatusMap: [
        { name: '全部', value: '' },
        { name: '待支付', value: 0 },
        { name: '待发货', value: 1 },
        { name: '已发货', value: 2 },
        { name: '已收货', value: 3 }
      ],
      btnFlag: { addShow: false, delShow: false },
      searchQuery: {},
      dataList: [],
      selecTionArr: [],
      pageInfo: {
        currentPage: 1,
        pageCount: 1,
        pageSize: 20,
        total: 0,
        apiName: '/order'
      },
      rules: {},
      searchProps: [
        { span: 2, type: 'input', label: '订单号', prop: 'id' },
        { span: 2, type: 'input', label: '商品名称', prop: 'name' },
        { span: 2, type: 'input', label: '收货人', prop: 'consignee' },
        {
          span: 3,
          type: 'select',
          label: '店铺',
          prop: 'storeId',
          selectData: []
        },
        {
          span: 2,
          type: 'select',
          label: '是否开团单',
          labelWidth: '80px',
          prop: 'isParticipants',
          selectData: [
            { label: '全部', value: '' },
            { label: '正常单', value: 0 },
            { label: '开团单', value: 1 }
          ]
        },
        {
          span: 2,
          type: 'select',
          label: '拼团状态',
          labelWidth: '80px',
          prop: 'participantsStatus',
          selectData: [
            { label: '全部', value: '' },
            { label: '拼团中', value: 0 },
            { label: '拼团成功', value: 1 },
            { label: '拼团失败', value: 2 }
          ]
        },
        {
          span: 2,
          type: 'select',
          label: '订单状态',
          labelWidth: '80px',
          prop: 'orderStatus',
          selectData: [
            { label: '全部', value: '' },
            { label: '待支付', value: 0 },
            { label: '待发货', value: 1 },
            { label: '已发货', value: 2 },
            { label: '已收货', value: 3 }
          ]
        }
      ]
    };
  },
  created() {
    this.getDataList();
  },
  mounted() {
    this.getTypeList();
    this.getStoreList();
  },
  methods: {
    // 跳到详情
    onJump(id) {
      this.$router.push({ path: '/order/orderDetail', query: { id: id } });
    },
    // 发货
    onDeliver(row) {
      this.form.id = row.id;
      if (row.distributionMode == 3) {
        this.wayList = [{ name: 'UU跑腿', value: 3 }];
      } else {
        this.wayList = [
          { name: '物流配送', value: 0 },
          { name: '商家配送', value: 1 },
          { name: '无需配送', value: 2 },
          { name: 'UU跑腿', value: 3 }
        ];
      }
      this.dialogShow = true;
    },
    // 退款
    onRefund(id) {
      this.refundForm.id = id;
      this.refundDialogShow = true;
    },
    // 主动退款
    async onRefundManual(id) {
      try {
        await this.$confirm('此操作将主动退款给用户, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });
        let res = await api.common.commonPost({
          apiName: '/order/examine',
          orderId: id,
          refundStatus: 5
        });
        if (res.code == 200) {
          this.$message.success('主动退款成功');
          this.getDataList();
        }
      } catch (error) {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      }
    },
    // 核销
    onWrite() {
      this.writeOffId = '';
      this.writeOffShow = true;
    },
    // 查询核销
    async onWriteEnter() {
      let loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)'
      });
      try {
        let res = await api.common.commonGet({
          apiName: '/order/writeOffDetail',
          writeOffCode: this.writeOffId
        });
        this.writeOffData = res.data;
      } catch (error) {
        console.log(error);
      } finally {
        loading.close();
      }
    },
    // 确认核销
    async onWriteConfirm() {
      let loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)'
      });
      try {
        let res = await api.common.commonPost({
          apiName: '/order/writeOff',
          writeOffCode: this.writeOffId
        });
        if (res.code == 200) {
          this.$message.success(res.message);
          this.writeOffShow = false;
        }
        await this.getDataList();
      } catch (error) {
        console.log(error);
      } finally {
        loading.close();
      }
    },
    // 关闭
    onWriteClose() {
      this.writeOffData = undefined;
    },
    // 打印小票
    async onPrint(row) {
      this.printerData = row;
      await this.$nextTick();
      this.printerDialogShow = true;
    },
    // 确认退款
    submitrefundForm() {
      let _this = this;
      this.$confirm('是否确定此操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let res = await api.common.commonPost({
            apiName: '/order/examine',
            orderId: this.refundForm.id,
            refundStatus: this.refundForm.refundStatus
          });

          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功!'
            });
            this.refundDialogShow = false;
            _this.getDataList();
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });
        });
    },
    // 搜索
    searchChange(e) {
      this.searchQuery = e;
      this.getDataList();
    },
    // 店铺
    async getStoreList() {
      let loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)'
      });
      try {
        let res = await api.common.commonGet({
          apiName: '/store',
          pageSize: 100
        });
        this.searchProps[1].selectData = [];
        res.data.data.forEach(element => {
          this.searchProps[3].selectData.push({
            label: element.storeName,
            value: element.id
          });
        });
      } catch (error) {
        console.log(error);
      } finally {
        loading.close();
      }
    },
    // 判断是不是店铺管理员
    checkRole() {
      const userInfo = store.getters.userInfo;
      let arr = [];
      userInfo.roleList.forEach(element => {
        arr.push(element.name);
      });
      if (arr.indexOf('storeAdmin') !== -1) {
        return userInfo.store.id;
      } else {
        return false;
      }
    },
    async onSizeChange(e) {
      this.pageInfo.pageSize = e;
      this.getDataList();
    },
    async onPageChange(e) {
      this.pageInfo.currentPage = e;
      this.getDataList();
    },
    // 订单列表
    async getDataList() {
      this.loading = true;
      try {
        let isStoreAdmin = this.checkRole();
        if (isStoreAdmin) {
          this.pageInfo.storeId = isStoreAdmin;
        }

        let obj = Object.assign({}, this.pageInfo, this.searchQuery);
        let res = await api.common.commonGet(obj);

        this.dataList = res.data.data;

        this.pageInfo.total = parseInt(res.data.total);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    // 确认发货
    async submitForm() {
      if (this.form.trackingNumber == '' && this.form.distributionMode == 0) {
        this.$message.error('请输入快递单号！');
        return;
      }
      let obj = Object.assign({}, { apiName: '/order/shipments' }, this.form);
      let res = await api.common.commonPost(obj);
      if (res.code == 200) {
        this.$message.success(res.message);
        this.dialogShow = false;
        this.getDataList();
      }
    },
    async getTypeList() {
      try {
        let res = await api.common.commonGet({
          apiName: '/systemDataDictionaryItem',
          systemDataDictionaryId: '1597521595575451649'
        });
        this.typeList = res.data.data;
      } catch (error) {
        console.log(error);
        // this.$message.error(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.newPrinterWrap {
  width: 193px;
}

.text-center i {
  font-style: normal;
}

.expandCon {
  margin: 0 10px;
  padding: 10px;
  background: #fff;
  font-size: 15px;
}

.printerWrap {
  margin: 0 auto;
}

.goodsName {
  display: block;
  margin-left: 75px;
}

.fl {
  float: left;
}

.line100 {
  line-height: 60px;
}

.tableCon .item {
  border: 1px solid #ebeef5;
  border-radius: 4px;
  margin-top: 20px;
}

.itemTop {
  background: #f5f7fa;
}

.changeCon p {
  color: #999;
}

.changeCon b {
  margin: 0 10px;
  color: #66b1ff;
}

.search {
  display: flex;
}

.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
